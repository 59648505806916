import { useState, useEffect } from 'react';
import {
	Button,
	Dialog,
	DialogHeader,
	DialogTitle,
	DialogContent,
	DialogFooter,
} from 'react-md';
import './entryModal.css';
import { CloseSVGIcon } from '@react-md/material-icons';
import { Stepper, Step } from 'react-form-stepper';
import _ from 'lodash';
import EntryWelcomeIntro from '../welcomeIntro/index';
import EntryIconCard from '../entryIconCard/index';
import EntryModalPrevNextIconButtons from '../entryPrevNextIconButtons/index';
import AgreementAcceptance from '../agreementAcceptance/index';
import usePageFramework, { currentYear } from '@utilities/hooks/usePageFramework';
import api from '@utilities/claApi';
import * as IMG from '@utilities/constants/images';
import { dashboard as defaultDashboard } from '@utilities/constants/dashboard';
import { loadVehicleData } from '@utilities/populatePriorData/vehicles/populateVehicleData.js';
import lastUserActivityDate from '@utilities/helpers/lastUserActivityDate';
import { deriveOrganizerActivity } from '@utilities/organizerData';
import MobileFooter from '../mobileFooter/mobileFooter';
import { isMobile } from 'react-device-detect';
import GettingStartedModal from '../gettingStarted';


function EntryExperienceModal() {
	const {
		history,
		NAV,
		REDUX,
		selectState,
		dispatch,
		ACTION,
		organizerId,
		validateCurrentCard,
		setEntityFormState,
		location
	} = usePageFramework();
	const dashboard = selectState(REDUX.DASHBOARD);
	const priorYearDetails = selectState(REDUX.PRIOR_YEAR_DETAILS);
	const dashboardYear = selectState(REDUX.YEAR);
	const lastUserActivity = lastUserActivityDate(selectState);

	const categories = dashboard ? dashboard : [];
	// Temporarily set  visible to false on app load
	const stepsList = [{ type: 'Income', title: 'Please tell us about your income' }, { type: 'Expenses', title: 'Please tell us about your expenses' }, { type: 'Services', title: 'Please see what additional services interest you' },];
	const [currentStep, setCurrentStep] = useState(0);
	const [isAgreementAcceptance, setIsAgreementAcceptance] = useState(false);
	const [isGettingStarted, setIsGettingStarted] = useState(false);
	const [isOpen, setIsOpen] = useState(!!location?.hash?.includes('#welcome'))
	const completedEntryStep = selectState(REDUX.COMPLETED_ENTRY_STEP);

	useEffect(() => {
		setIsOpen(!!location?.hash?.includes('#welcome') && dashboardYear === currentYear)
	}, [location])

	const onCloseModal = () => {
		history.push(NAV.DASHBOARD)
	}

	const checkEntryExperience = (currentId) => {
		if (currentId) {
			dispatch(ACTION.setFutureYear(false));
		}
		if (completedEntryStep) {
			setIsOpen(false)
			dispatch(ACTION.setHasSkipEntryStep(true));
			history.push(NAV.DASHBOARD)
		} else {
			setIsAgreementAcceptance(true);
			setIsGettingStarted(false);
			setCurrentStep(0)
		}
	};

	const showGettingStarted = () => {

		if (completedEntryStep) {
			setIsOpen(false)
			dispatch(ACTION.setHasSkipEntryStep(true));
			history.push(NAV.DASHBOARD)
		} else {
			setIsAgreementAcceptance(false);
			setIsGettingStarted(true);
			setCurrentStep(0);
		}
	};

	const navigateToPreviousPage = () => {
		setIsAgreementAcceptance(false);
		setIsGettingStarted(true);
		setCurrentStep(0);
	};

	const buildStepStyle = (index) => {
		let stepStyle = <div data-testid={'build-step-dot-' + index} className={index < stepIndex ? 'entryExperienceCompletedCheck' : index === stepIndex ? 'entryExperienceActiveDot' : index > stepIndex ? 'entryExperienceInactiveDot' : 'entryExperienceInactiveDot'}></div>;
		return (
			//eslint-disable-next-line
			<a data-testid={'build-step-id-' + index} onClick={() => setCurrentStep(index + 1)}>{stepStyle}</a>
		);
	};

	const buildStepView = () => {
		const cardsByType = []

		for (let i = 0; i < categories.length; i++){
			
			const cardGroup = categories[i].cards.filter(card =>
			card.entryType === stepsList[stepIndex]?.type
			)
			if (cardGroup.length > 0) {
				cardsByType.push(cardGroup)
			}
		}

		const foreignAssetsCard = categories[0]?.cards?.find(card => card.formName === 'foreignAssets');
		return (
			<div className="entryIconContainer">
				{
					cardsByType[0]?.map((card, index) => (
						<EntryIconCard key={`entry-card-${card.key}-${index}`} card={card} />
					))
				}
				{cardsByType[0]?.[0].entryType === 'Income' ? <EntryIconCard key={`entry-card-${foreignAssetsCard.key}`} card={foreignAssetsCard} /> : null}
			</div>

		);
	};

	const saveEntryExperience = (isMobileSave) => {
		dispatch(ACTION.setProgressText('Just a moment...'));
		dispatch(ACTION.setProgressVisible(true));
		const completedStep = isMobileSave ? 1 : stepIndex + 1;
		const json = {
			entryExperience: {
				completedStep: completedStep,
			},
			lastUserActivityOn: lastUserActivity,
			status: 'In Progress'
		};

		const derivedOrganizerData = deriveOrganizerActivity(json, new Date());
		const payload = Object.assign({}, json, derivedOrganizerData);

		api.put(`/organizers/${organizerId}`, payload).then(() => {
            dispatch(ACTION.setCompletedEntryStep(completedStep));
			dispatch(ACTION.setHasSkipEntryStep(true));
        }).then(() => {
            return api.get(`organizers/${organizerId}/dashboard`);
        }).then((dashboardResponse) => {
            const { id: dashboardId } = dashboardResponse.data;

            const dashboardUpdateBody = { dashboard: categories };
            return api.put(`organizers/${organizerId}/dashboard/${dashboardId}`, dashboardUpdateBody);
		}).catch((error) => {
			console.error(error);
			dispatch(ACTION.setShowCustomDialog(true));
			dispatch(ACTION.setCustomDialogTitle('An unexpected error occured.'));
			dispatch(ACTION.setCustomDialogMsg('Please try again later.'));

		}).finally(() => {
			dispatch(ACTION.setProgressVisible(false));
			onCloseModal();
		});
	};
	const showProgressDialog = () => {
		dispatch(ACTION.setProgressText('Loading Client Data...'));
		dispatch(ACTION.setProgressVisible(true));
	};

	const hideProgressDialog = () => {
		dispatch(ACTION.setProgressText(''));
		dispatch(ACTION.setProgressVisible(false));
	};
	const showPriorTaxInformation = async () => {
		showProgressDialog();
		dispatch(ACTION.setFutureYear(false));
		dispatch(ACTION.setUploadList([]));
		api.get(`/organizers/${priorYearDetails.priorOrganizerId}`, {}).then(async (result) => {
			let hasVehicleData = false;
			if (!result?.data) throw new Error('No data available');
			const { dashboard } = await api.get(`organizers/${result?.data?.id}/dashboard`).then(response => response.data);

			const { forms, client, year, status, notes } = result.data;
			const priorData = await api.get(`/organizers/${priorYearDetails.priorOrganizerId}/prior`).then((response) => {
				dispatch(ACTION.setPriorYearData(response.data.data.taxData.priorYear));
				return response.data.data.taxData;
			}).catch((err) => {
				console.error(err);
			});
			// Set dashboard data into redux
			const newDash = _.cloneDeep(defaultDashboard);
			dispatch(ACTION.setDashboard(dashboard ? dashboard : newDash));

			// Set form data into redux
			const formKeys = [];
			forms?.forEach((form) => {
				if (!formKeys.includes(form.key)) {
					dispatch(ACTION.setForm(form.key, form.data));
					hasVehicleData = hasVehicleData || _.startsWith(form.key, REDUX.VEHICLE);
					formKeys.push(form.key);
				}
			});

			// Load vehicles if the client does not have any vehicle data
			if (priorData && priorData.priorYear && !hasVehicleData) {
				const vehicleFormData = loadVehicleData(priorData.priorYear);
				if (vehicleFormData && Object.keys(vehicleFormData).length) {
					Object.entries(vehicleFormData).forEach(([vehicleKey, vehicleForm]) => {
						setEntityFormState(vehicleKey, vehicleForm, organizerId);
					});
				}
			}

			// Set organizer metadata
			const clientInfo = {
				displayName: client.name,
				clientNumber: client?.number ?? '',
				currentYear: year,
				formStatus: status
			};

			await api.get(`organizers/${priorYearDetails.priorOrganizerId}/notes`).then((response) => {
				dispatch(ACTION.setFormNotes(response.data.results));
			})

			dispatch(ACTION.setOrganizerId(priorYearDetails.priorOrganizerId));
			dispatch(ACTION.setActiveReturn(clientInfo));
			dispatch(ACTION.setLockForms(true));
			dispatch(ACTION.setYear(year));
		}).catch((err) => {
			console.error(err);
		}).finally(() => {
			hideProgressDialog();
			onCloseModal();
		})
	};

	const showUploadDocument = async () => {
		validateCurrentCard();
		dispatch(ACTION.setCurrentCardKey(null));
		let futureYear = currentYear + 1;
		dispatch(ACTION.setYear(futureYear));
		dispatch(ACTION.setFutureYear(true));
		history.push(`${NAV.DOCUMENT_MANAGER}`);
	}

	const stepIndex = currentStep - 1;

	const mobileStyles = {
		backgroundImage: `url(${IMG.ENTRY_EXPERIENCE_MOBILE_BACKGROUND}), url(${IMG.ENTRY_EXPERIENCE_MOBILE_BACKGROUND_RECTANGLE})`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		position: 'fixed',
		// setting height and width makes the dialog unresponsive on smaller screens
		// height: '696px', 
		// width: '902px',
		// padding: '56px 130px 22px 15px'
	};

	return (
		<>
			<Dialog
				id="simple-dialog"
				visible={isOpen}
				onRequestClose={() => { }}
				aria-labelledby="entry-experience-dialog-title"
				className={isMobile ? "entry-dialog-mobile-container" : "entry-dialog-container"}
				data-testid="simple-dialog-container"
				style={isMobile ? mobileStyles : null}
			>

				{
					!isAgreementAcceptance && !isGettingStarted ? <EntryWelcomeIntro data-testid="entry-welcomeIntro" moveAgreement={checkEntryExperience} showPriorTaxInformation={showPriorTaxInformation} showUploadDocument={showUploadDocument} /> :
						isAgreementAcceptance && !isGettingStarted && !currentStep ? <AgreementAcceptance setCurrentStep={setCurrentStep} mobileSaveEntry={saveEntryExperience} showGettingStarted={showGettingStarted} /> : !isAgreementAcceptance && isGettingStarted && !currentStep ? <GettingStartedModal setCurrentStep={setCurrentStep} mobileSaveEntry={saveEntryExperience} setIsOpen={setIsOpen} /> :
							<>
								<div>
									<img src={IMG.CLA_NAVY_LOGO} alt="CLA navy colored logo" className="entryHeaderSilIcon"></img>
									<Button
										id="icon-button-close"
										data-testid="icon-button-close"
										buttonType="icon"
										theme="clear"
										aria-label="close"
										className="entryExperienceModalCloseBtn"
										onClick={() => saveEntryExperience()}
									>
										<CloseSVGIcon className="closeIcon" />
									</Button>
									<DialogHeader>
										<DialogTitle id="entry-experience-dialog-title" className="entry-experience-title">
											Welcome to the <span className="entry-experience-app-title">1040 Exchange</span>
										</DialogTitle>
									</DialogHeader>
								</div>
								<DialogContent className="entry-modal-content entry-modal-content-mobile">
									<div className="entry-step-text-container">
										<div className="entry-please-tell-us-about">{stepsList[stepIndex]?.title}</div>
										<div className="entry-step-description">To get things started, we highlighted the items we worked on with you last year.</div>
										<div className="entry-step-description">Please select or deselect anything that has changed.</div>
									</div>

									<Stepper
										activeStep={stepIndex}
										connectorStateColors={true}
										styleConfig={{ inactiveBgColor: '#FFFFFF', activeBgColor: '#7DD2D3', completedBgColor: '#7DD2D3', size: '24px', circleFontSize: '12px' }}
										connectorStyleConfig={{ disabledColor: '#d2d2d2', activeColor: '#7dd2d3', completedColor: '#7dd2d3', size: 3, }}
									>
										{stepsList.map((step, index) => (
											<Step key={`entry-modal-step${index}`} label={step.type}> {buildStepStyle(index)}</Step>
										))}
									</Stepper>

									{buildStepView()}

									<DialogFooter className='entryExperienceFooter'>
										<EntryModalPrevNextIconButtons setCurrentStep={setCurrentStep} stepIndex={stepIndex} saveStep={saveEntryExperience} navigateToPreviousPage={navigateToPreviousPage} />
									</DialogFooter>
								</DialogContent>
							</>
				}
				{isMobile ? <MobileFooter /> : null}
			</Dialog>
		</>
	);
}

export default EntryExperienceModal;